import {httpGet, httpPost, httpPut} from '@/plugins/request'


/**
 * 获取群发列表
 *
 * @returns {Promise<*>}
 */
export function apiGetWechatGroups() {
    return httpGet('/manage/wechat/group')
}

/**
 * 新增群发
 *
 * @param data
 * @returns {Promise<*>}
 */
export function apiPostWechatGroup (data) {
    return httpPost('/manage/wechat/group', data)
}

/**
 * 修改群发
 *
 * @param id
 * @param data
 * @returns {Promise<*>}
 */
export function apiPutWechatGroup (id, data) {
    return httpPut(`/manage/wechat/group/${id}`, data)
}

/**
 * 修改状态
 *
 * @param id
 * @returns {Promise<*>}
 */
export function apiPutWechatGroupStatus (id) {
    return httpPut(`/manage/wechat/group/${id}/status`, id)
}

/**
 * 手动触发发送群消息
 * @param id
 * @returns {Promise<*>}
 */
export function apiSendWechatGroupMessage(id) {
    return httpPost(`/manage/wechat/group/${id}/send`)
}
