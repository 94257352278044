import {httpGet, httpPost} from '@/plugins/request'


/**
 * 获取微信群
 * @param query
 * @returns {Promise<*>}
 */
export function apiGetWxGroups(query = []) {
    return httpGet('/manage/vbot/group', query)
}

/**
 * 获取微信群详情
 * @param id
 * @param query
 * @returns {Promise<*>}
 */
export function apiGetWxGroup(id, query = []) {
    return httpGet(`/manage/vbot/group/${id}`, query);
}

/**
 * 获取消息列表
 *
 * @param query
 * @returns {Promise<*>}
 */
export function apiGetWxMessages(query = []) {
    return httpGet('/manage/vbot/message', query);
}

/**
 * 撤回消息
 *
 * @param id
 * @returns {Promise<*>}
 */
export function apiRevokeMessage(id) {
    return httpPost(`/manage/vbot/message/${id}/revoke`)
}

/**
 * 获取微信账号
 * @param query
 * @returns {Promise<*>}
 */
export function apiGetWx(query = []) {
    return httpGet('/manage/vbot/wechat', query)
}

/**
 * 发送消息
 * @param data
 * @returns {*}
 */
export function apiWxSend(data) {
    return httpPost('/manage/wechat/send', data)
}
