<template>
  <div>
    <!----- 搜索框 start------->
    <el-form :inline="true" class="search">
      <el-form-item label="任务名">
        <el-input v-model="searchData.name" placeholder="任务名搜索" clearable />
      </el-form-item>
      <el-form-item>
        <el-button @click="getWechatGroup()">查询</el-button>
      </el-form-item>

      <el-form-item style="float: right">
        <el-button type="primary" @click.stop="clickCreate()">创建任务</el-button>
      </el-form-item>
    </el-form>
    <!----- 搜索框 end------->


    <!----- 表格 start------->
    <el-table :data="tableData" :border="true" empty-text="暂无数据" style="width: 100%">
      <el-table-column prop="id" label="ID" width="80" />
      <el-table-column prop="name" label="任务名称" />
      <el-table-column label="发信账号">
        <template #default="scope">
          <span v-text="proxy.wechat[scope.row.wxid]"></span>
        </template>
      </el-table-column>
      <el-table-column label="消息类型" width="100">
        <template #default="scope">
          <span v-text="proxy.type[scope.row.type]"></span>
        </template>
      </el-table-column>
      <el-table-column label="发送类型" width="100">
        <template #default="scope">
          <span v-text="proxy.send_type[scope.row.send_type]"></span>
        </template>
      </el-table-column>
      <el-table-column label="状态" width="80">
        <template #default="scope">
          <el-switch
              @change="updateGroupStatus(scope.row.id)"
              :active-value="1"
              :inactive-value="2"
              v-model="scope.row.status"
          />
        </template>
      </el-table-column>
      <el-table-column prop="last_at" label="上次运行时间" />
      <el-table-column prop="next_at" label="下次运行时间" />
      <el-table-column label="操作">
        <template #default="scope">
          <el-button type="primary" size="small" @click="clickEdit(scope.row)">修改</el-button>
          <el-button type="success" size="small" @click="clickSend(scope.row)">发送消息</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!----- 表格 end------->


    <!----- 分页 start------->
    <div class="cherry-page" v-show="pageData.total">
      <el-pagination
          small
          background
          layout="prev, pager, next, total"
          :total="pageData.total"
          v-model:current-page="searchData.page"
          @update:current-page="getWechatGroup()"
          class="mt-4"
      />
    </div>
    <!----- 分页 end------->

    <!-------- 弹出框表单 start ---------->
    <el-dialog v-model="formData.show" title="编辑任务" fullscreen append-to-body>
      <el-form
          ref="check"
          label-width="100px"
          :rules="formData.rules"
          :model="formData.data"

      >
        <el-form-item label="发信微信" prop="wxid">
          <el-select v-model="formData.data.wxid" @change="eventWechatChange">
            <el-option
                v-for="(item, index) in data.wechat"
                :key="index"
                :label="item.nickname"
                :value="item.wxid"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="发送的群" prop="data.groups">
          <el-select
              multiple
              filterable
              v-model="formData.data.data.groups"
          >
            <el-option
                v-for="(item, index) in data.groups"
                :key="index"
                :label="item.nickname"
                :value="item.wxid"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="任务名称" prop="name">
          <el-input v-model="formData.data.name" placeholder="输入任务名称"/>
        </el-form-item>

        <el-form-item label="消息类型" prop="type">
          <el-select v-model="formData.data.type">
            <el-option
                v-for="(item,index) in proxy.type"
                :key="index"
                :label="item"
                :value="index"/>
          </el-select>
        </el-form-item>

        <el-form-item label="图片路径" prop="data.file" v-show="formData.data.type === 'MT_SEND_IMGMSG'">
          <el-input v-model="formData.data.data.file" placeholder="机器人电脑上的图片路径吗；例如 C:\abc\1.jpg"/>
        </el-form-item>

        <el-form-item label="发送内容" prop="data.content" v-show="formData.data.type === 'MT_SEND_TEXT_V2_MSG'">
          <el-input
              type="textarea"
              rows="6"
              maxlength="500"
              show-word-limit
              placeholder="需要发送的内容"
              v-model="formData.data.data.content" />
        </el-form-item>

        <el-form-item label="发送方式" prop="send_type">
          <el-select v-model="formData.data.send_type">
            <el-option label="手动发送" :value="1"></el-option>
            <el-option label="定时发送" :value="2"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="发送间隔" prop="data.everytime" v-show="formData.data.send_type === 2">
          <el-select v-model="formData.data.data.everytime">
            <el-option label="30分" :value="1800"></el-option>
            <el-option label="1小时" :value="3600"></el-option>
            <el-option label="2小时" :value="7200"></el-option>
            <el-option label="4小时" :value="14400"></el-option>
            <el-option label="6小时" :value="21600"></el-option>
            <el-option label="12小时" :value="43200"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="发送时段" prop="data.range_time" v-show="[2, 3].indexOf(formData.data.send_type) > -1">
          <el-time-picker
              v-model="formData.data.data.range_time"
              arrow-control
              editable
              is-range
              value-format="HHmm"
              format="HH:mm"
              range-separator="至"
          />
        </el-form-item>
      </el-form>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="closeDialog">取消</el-button>
          <el-button type="primary" @click="onSubmit">提交</el-button>
        </span>
      </template>
    </el-dialog>
    <!-------- 弹出框表单 end ---------->

  </div>
</template>

<script>
  import {apiGetWx, apiGetWxGroups} from '@/api/wechatApi'
  import {apiGetWechatGroups, apiPostWechatGroup, apiPutWechatGroup, apiSendWechatGroupMessage, apiPutWechatGroupStatus} from "@/api/wechatGroupApi";
  const form = {
    name: '',
    wxid: '',
    type: '',
    send_type: 1,
    data: {
      content: '',
      file: '',
      groups: [],
      everytime: 3600,
      times: [],
      range_time: ['0000', '2359'],
    }
  }
  export default {
    name: 'message.group',
    created() {
      this.getWx()
      this.getWechatGroup()
    },
    data () {
      return {
        tableData: [],
        searchData: {
          name: '',
        },
        pageData: {
          total: 0,
        },
        data: {
          wechat: [],
          groups: [],
        },
        // 映射值
        proxy: {
          wechat: {},
          status: {
            1: '正常',
            2: '暂停',
          },
          type: {
            'MT_SEND_IMGMSG': '本地图片',
            'MT_SEND_TEXT_V2_MSG': '文本',
          },
          send_type: {
            1: '手动发送',
            2: '定时发送',
            3: '指定时段发送'
          },
        },
        // 创建表单
        formData:{
          lock: false,
          data: form,
          rules: {
            name: [{required: true, message: '请输入任务名称', trigger: 'blur',}],
            wxid: [{required: true, message: '请选择发信微信', trigger: 'blur',}],
            type: [{required: true, message: '请选择消息类型', trigger: 'blur',}],
            send_type: [{required: true, message: '请选择发送方式', trigger: 'blur',}],
            'data.groups': [{required: true, message: '请选择群', trigger: 'blur',}],
          },
          show: false,
        },
      }
    },
    methods: {
      // 获取群发列表
      getWechatGroup () {
        apiGetWechatGroups(this.searchData).then(data => {
          this.tableData = data.data
          this.pageData = data.meta
        })
      },
      updateGroupStatus (id) {
        apiPutWechatGroupStatus(id).then(() => {
          this.$message.success('修改成功')
          return true
        }).catch(() => {
          return false
        })
      },
      // 微信
      getWx() {
        apiGetWx().then(data => {
            this.data.wechat = data
            data.forEach((item) => {
              this.proxy.wechat[item.wxid] = item.nickname
            })
        })
      },
      // 微信群
      eventWechatChange () {
        if (this.data.groups.length) {
          return;
        }

        apiGetWxGroups({wxid: this.formData.data.wxid}).then(data => {
          this.data.groups = data
        })
      },
      onSubmit () {
        if (this.formData.data.id) {
          apiPutWechatGroup(this.formData.data.id, this.formData.data).then(() => {
            this.$message.success('修改成功')
            this.getWechatGroup()
            this.closeDialog()
          })
        } else {
          apiPostWechatGroup(this.formData.data).then(() => {
            this.$message.success('创建成功')
            this.getWechatGroup()
            this.closeDialog()
          })
        }
      },
      // 点击创建
      clickCreate () {
        this.formData.data = form
        this.showDialog()
      },
      // 点击编辑
      clickEdit (item) {
        this.formData.data = item
        this.eventWechatChange()
        this.showDialog()
      },
      // 发送消息
      clickSend (row) {
        apiSendWechatGroupMessage(row.id).then(() => {
          this.$message.success('操作成功，消息发送有一定延迟,详情可查看发送记录')
        })
      },
      // 显示弹窗
      showDialog() {
        this.formData.show = true
      },
      closeDialog() {
        this.formData.show = false
      },
    }
  }
</script>
